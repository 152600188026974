import CCP_MySwiper from '@/components/main/swiper/MySwiper'
import ArabicDoctorCard from './ArabicDoctorCard'
import Image from 'next/image'
import SCP_ArabicSectionTitle from './ArabicSectionTitle'

export default function SCP_ArabicCenterDoctor({ content, isLoading = false }) {
  if (!content || !content?.cards) return null

  return (
    <div className="tw-bg-[#8EA4C726]/15 sm:tw-py-20 tw-py-12 tw-relative">
      <div className="tw-container">
        {/* Title */}
        <SCP_ArabicSectionTitle
          heading={content?.heading_text}
          subHeading={content?.subheading_text}
        />
        <CCP_MySwiper
          breakpoints={{
            768: {
              slidesPerView: 2.4,
              spaceBetween: 24,
            },
            1080: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 24,
            },
          }}
          slidesPerView={1.2}
          spaceBetween={16}
          swiperClass="tw-mt-12 related-swiper"
          watchSlidesProgress={true}
          navigation
          pagination={{ clickable: true, dynamicMainBullets: 4 }}
          slides={(isLoading ? new Array(8).fill(0) : content?.cards).map(
            (card, i) => (
              <ArabicDoctorCard
                key={`center-doctor-${i}`}
                card={card}
                isLoading={isLoading}
              />
            )
          )}
        />
        <div className="tw-absolute tw-w-full tw-h-full  tw-top-0 tw-left-0 tw-flex tw-items-center tw-justify-center">
          <Image
            src="https://cdn.bangkokhospital.com/2025/01/arabic-curve-decoration.svg"
            alt="Doctor Related"
            width={1920}
            height={500}
            className="tw-w-full tw-h-[500px] tw-object-[100% 50%] "
          />
        </div>
      </div>
    </div>
  )
}
