import SCP_ArabicSectionTitle from '@/components/main/arabic/ArabicSectionTitle'
import CCP_ImageFallback from '@/components/main/element/ImageFallback'

export default function SCP_ArabicCenterDetail({ content, centerGroupTitle }) {
  if (!content) return null
  return (
    <div className="sm:tw-py-20 tw-py-12 tw-bg-[#8EA4C726]/15 shadow-section-arabic-blue">
      <div className="tw-container">
        {/* Title */}
        <SCP_ArabicSectionTitle
          heading={content?.heading_text}
          subHeading={content?.subheading_text}
        />
        {/* Content */}
        <div className="tw-grid sm:tw-grid-cols-2 sm:tw-gap-16 tw-gap-8 tw-mt-8">
          <div className="tw-relative tw-pb-[80%] tw-bg-bgh-gray-light">
            <CCP_ImageFallback
              alt={`${centerGroupTitle} Detail`}
              src={content?.content_image?.url}
              fill={true}
              className="tw-object-cover"
            />
          </div>
          <div
            className="tw-text-primary entry-content tw-self-center"
            dangerouslySetInnerHTML={{ __html: content?.content_text }}
          />
        </div>
      </div>
    </div>
  )
}
