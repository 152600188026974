import { useTranslations } from 'next-intl'
import Image from 'next/image'
const LoadingSendingState = ({ title = 'sending' }) => {
  const __t = useTranslations()
  return (
    <div className="tw-top-0 tw-left-0 tw-flex tw-space-x-2  tw-text-primary tw-font-bold tw-w-full tw-h-full tw-z-50  tw-fixed tw-items-center tw-justify-center backdrop-filter -white  ">
      <div className="tw-p-8 tw-rounded-2xl tw-bg-white/50 tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-1 backdrop-filter -white ">
        <Image
          src="/vip/loading.gif"
          width={48}
          height={48}
          alt="Loading"
          priority={true}
          className="tw-object-contain tw-grayscale"
        />
        <p>{__t(title)}</p>
      </div>
    </div>
  )
}

export default LoadingSendingState
