import CCP_TransitionImageChild from '@/components/main/element/TransitionImageChild'
import CCP_ArabicContactForm from '@/components/main/arabic/ArabicContactForm'

export default function SCP_ArabicCenterBanner({ centerGroupTitle, content }) {
  if (!content) return null
  return (
    <div className="tw-relative tw-max-h-none tw-overflow-hidden shadow-section-arabic-blue">
      {/* Content */}
      <div className="tw-relative tw-z-10 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center sm:tw-py-24 tw-py-16">
        <div className="tw-container tw-grid md:tw-grid-cols-2 md:tw-gap-32 sm:tw-gap-16 xs:tw-gap-8 tw-gap-4">
          {/* Title */}
          <div className="bg-gradient-arabic-blue-fade sm:tw-p-4 tw-px-2 tw-py-3 tw-text-right tw-h-min">
            <h1 className="tw-text-white sm:tw-text-3xl tw-text-2xl tw-font-bold">
              {content?.heading_text}
            </h1>
            <p className="tw-text-white sm:tw-text-3xl tw-text-xl tw-font-normal">
              {content?.subheading_text}
            </p>
          </div>
          {/* Lead form */}
          <CCP_ArabicContactForm content={content} />
        </div>
      </div>
      {/* Background */}
      <div className="tw-absolute tw-z-0 tw-top-0 tw-left-0 tw-w-full tw-h-full">
        <CCP_TransitionImageChild
          src={content?.cover_image?.url}
          alt={`${centerGroupTitle} Banner`}
          priority={true}
          transition="object-position"
        />
      </div>
    </div>
  )
}
