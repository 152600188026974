'use client'

import CCP_ImageFallback from '../element/ImageFallback'

const ArabicDoctorCard = ({ card, isLoading }) => {
  return (
    <div
      className={`tw-h-full tw-p-4 bg-gradient-arabic-blue-light tw-shadow-main-dark-2 tw-rounded-[20px] tw-overflow-hidden tw-duration-200 ${
        isLoading ? 'tw-pointer-events-none' : ''
      }`}
    >
      {/* Profile Image */}
      <div className="tw-relative tw-w-full tw-pb-[80%]">
        <CCP_ImageFallback
          alt="Doctor Image"
          src={card?.cover_image?.url}
          fill={true}
          className="tw-object-contain"
        />
      </div>
      {/* Details */}
      <h3
        className="tw-text-bgh-dark !tw-font-bold tw-capitalize animated-underline tw-text-xl tw-mt-2"
        dangerouslySetInnerHTML={{ __html: card?.name }}
      />
      {/* subheading_text */}
      <h4 className="tw-text-bgh-gray-primary tw-text-sm max-sm:tw-line-clamp-2 tw-mt-4">
        {card?.subheading_text}
      </h4>
      {/* excerpt */}
      <h6 className="tw-font-medium tw-text-sm tw-line-clamp-6 tw-my-6">
        {card?.excerpt}
      </h6>
    </div>
  )
}

export default ArabicDoctorCard
