'use client'
import nationalities from '@/assets/json/epms-nationalities.json'

import PrimaryButton from '@/components/main/button/PrimaryButton'
import useGlobal from '@/store/global'
import { useLocale, useTranslations } from 'next-intl'
import { useReCaptcha } from 'next-recaptcha-v3'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import LoadingSendingState from '../element/LoadingSendingState'
import LoadingState from '../element/LoadingState'
import debounce from 'lodash.debounce'
import validateEmail from '@/utils/validateEmail'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
} from '@mui/material'
import NativeOption from '@/components/main/form/NativeOption'
import { MobileDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import useAppointment from '@/store/appointment'

export default function CCP_ArabicContactForm({ content }) {
  const __t = useTranslations()
  const locale = useLocale()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    setIsLoaded(true)
  }, [])
  const { executeRecaptcha } = useReCaptcha()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      sender_email: '',
      phone_number: '',
      nationality: '',
      dob: '',
      specialty: '',
      id: '',
      preferred_date: '',
      message: '',
    },
  })
  const { setForm, submitNonRealtimeAppointment } = useAppointment()
  if (!content?.lead_form) return null
  // Submit form
  const onSubmit = debounce(async function (formObject) {
    setIsSubmitting(true)
    const {
      name,
      sender_email,
      phone_number,
      nationality,
      dob,
      specialty,
      id,
      preferred_date,
      message,
    } = formObject
    const dataObj = {
      title: name,
      email: sender_email,
      phone_number,
      date_of_birth: dob,
      doctor_specialty: specialty,
      doctor_name: '',
      id,
      preferred_date,
      preferred_time: '',
      nationality,
      medical_info: message,
      website_url: typeof window !== 'undefined' ? window?.location?.href : '',
      code: 'BHQ-ARABIC',
      recipient_staff_emails: content?.lead_form?.recipient_staff_emails,
    }
    setForm(dataObj)
    const submitToken = await executeRecaptcha(
      'arabic_appointment_submit_non_realtime'
    )
    // Submit
    const res = await submitNonRealtimeAppointment({
      submitToken,
    })
    useGlobal.setState({
      successModalContent: res
        ? __t('submitted-successfully')
        : __t('error-occured'),
    })
    setIsSubmitting(false)
  }, 1000)

  // date
  const tomorrow = dayjs().add(1, 'day')
  const daysInAdvanced = 90 //limit by EPMS
  const maxDate = dayjs().add(daysInAdvanced, 'day')

  if (!isLoaded) return <LoadingState />
  return (
    <div className="shadow-top-arabic-blue sm:tw-max-w-md tw-mx-auto tw-w-full">
      <div className="tw-bg-white/70 tw-w-full tw-h-full tw-p-4 square-gradient-border">
        <h2 className="text-gradient-arabic tw-text-2xl tw-my-2">حجز موعد</h2>
        <div>
          {isSubmitting ? <LoadingSendingState /> : null}
          <form
            action={handleSubmit(async (formObject) => {
              onSubmit(formObject)
            })}
            className="tw-max-w-2xl tw-mx-auto tw-flex tw-flex-col tw-space-y-8 tw-items-center"
          >
            {/* Form */}
            <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-w-full">
              {/* name */}
              <Controller
                control={control}
                name="name"
                rules={{
                  required: __t('field-required'),
                  maxLength: 100,
                }}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <TextField
                      label={value ? __t('name') : null}
                      placeholder={__t('name')}
                      name="name"
                      fullWidth
                      inputRef={ref}
                      value={value || ''}
                      onChange={onChange}
                      type="text"
                      variant="outlined"
                      className="tw-col-span-2 tw-rounded-lg"
                      inputProps={{
                        className: '!tw-py-3',
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          backgroundColor: 'white',
                        },
                      }}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )
                }}
              />
              {/* sender_email */}
              <Controller
                control={control}
                name="sender_email"
                rules={{
                  required: __t('field-required'),
                  // email pattern
                  validate: (value) =>
                    validateEmail(value) || __t('invalid-email'),
                  maxLength: 30,
                }}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <TextField
                      label={value ? __t('email') : null}
                      placeholder={__t('email')}
                      fullWidth
                      name="sender_email"
                      type="email"
                      variant="outlined"
                      inputRef={ref}
                      value={value || ''}
                      onChange={onChange}
                      error={!!errors.sender_email}
                      helperText={errors.sender_email?.message}
                      className="tw-col-span-2 sm:tw-col-span-1 tw-rounded-lg"
                      inputProps={{
                        className: '!tw-py-3 tw-text-right',
                        dir: 'ltr',
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          backgroundColor: 'white',
                        },
                      }}
                    />
                  )
                }}
              />
              {/* phone_number */}
              <Controller
                control={control}
                name="phone_number"
                rules={{
                  required: __t('field-required'),
                  pattern: {
                    value: /^[0-9]{8,20}$/,
                    message: __t('invalid-phone'),
                  },
                }}
                render={({ field: { onChange, ref, value } }) => (
                  <TextField
                    inputRef={ref}
                    label={__t('phone')}
                    value={value || ''}
                    type="tel"
                    fullWidth
                    onChange={onChange}
                    className="tw-col-span-2 sm:tw-col-span-1 tw-rounded-lg"
                    inputProps={{
                      className: '!tw-py-3 tw-text-right',
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        backgroundColor: 'white',
                      },
                    }}
                    error={!!errors.phone_number}
                    helperText={errors.phone_number?.message}
                  />
                )}
              />
              {/* nationality */}
              <Controller
                control={control}
                name="nationality"
                rules={{ required: __t('field-required') }}
                render={({ field: { onChange, ref, value } }) => (
                  <FormControl
                    fullWidth
                    error={!!errors.nationality}
                    className="tw-col-span-2"
                    sx={{
                      '& .MuiInputBase-root': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    <InputLabel id="nationality" required className="-tw-mt-1">
                      {__t('nationality')}
                    </InputLabel>
                    <Select
                      ref={ref}
                      native
                      labelId="nationality"
                      id="nationality"
                      value={value || ''}
                      label={__t('nationality')}
                      onChange={onChange}
                      inputProps={{
                        className: '!tw-py-3',
                      }}
                    >
                      <option disabled value="" />
                      {nationalities.map((item) => (
                        <NativeOption key={item.code} value={item.code}>
                          {item.description}
                        </NativeOption>
                      ))}
                    </Select>
                    <FormHelperText error={!!errors.nationality}>
                      {errors.nationality?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              {/* dob */}
              <Controller
                name="dob"
                control={control}
                rules={{ required: __t('field-required') }}
                render={({ field: { onChange, ref, value } }) => (
                  <>
                    <MobileDatePicker
                      disableFuture
                      openTo="year"
                      minDate={dayjs(dayjs().subtract(100, 'year').toDate())}
                      label={__t('date-of-birth')}
                      inputFormat="DD/MM/YYYY"
                      format="DD/MM/YYYY"
                      value={dayjs(value) || null}
                      onChange={onChange}
                      className="tw-col-span-2 tw-rounded-lg"
                      sx={{
                        '& .MuiInputBase-root': {
                          backgroundColor: 'white',
                        },
                      }}
                      slotProps={{
                        textField: {
                          required: true,
                          helperText: errors.dob?.message,
                          error: !!errors.dob,
                          InputLabelProps: { required: true },
                          ref,
                          fullWidth: true,
                          inputProps: {
                            className: '!tw-py-3',
                          },
                        },
                      }}
                    />
                  </>
                )}
              />
              {/* specialty */}
              <Controller
                control={control}
                name="specialty"
                rules={{
                  required: __t('field-required'),
                  maxLength: 100,
                }}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <TextField
                      label={value ? __t('specialty') : null}
                      placeholder={__t('specialty')}
                      name="specialty"
                      fullWidth
                      inputRef={ref}
                      value={value || ''}
                      onChange={onChange}
                      type="text"
                      variant="outlined"
                      className="tw-col-span-2 tw-rounded-lg"
                      inputProps={{
                        className: '!tw-py-3',
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          backgroundColor: 'white',
                        },
                      }}
                      error={!!errors.specialty}
                      helperText={errors.specialty?.message}
                    />
                  )
                }}
              />
              {/* passport */}
              <Controller
                name="id"
                control={control}
                rules={{ required: __t('field-required') }}
                render={({ field: { onChange, ref, value } }) => (
                  <TextField
                    inputRef={ref}
                    label={__t('passport-number')}
                    fullWidth
                    value={value || ''}
                    onChange={onChange}
                    className="tw-col-span-2 tw-rounded-lg"
                    inputProps={{
                      className: '!tw-py-3',
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        backgroundColor: 'white',
                      },
                    }}
                    error={!!errors.id}
                    helperText={errors.id?.message}
                  />
                )}
              />
              {/* preferred_date */}
              <Controller
                name="preferred_date"
                control={control}
                rules={{
                  required: __t('please-specify'),
                }}
                render={({ field: { onChange, ref, value } }) => (
                  <>
                    <MobileDatePicker
                      minDate={tomorrow}
                      maxDate={maxDate}
                      disablePast={true}
                      label={__t('preferred-date')}
                      inputFormat="DD/MM/YYYY"
                      format="DD/MM/YYYY"
                      value={dayjs(value) || null}
                      onChange={(date) => {
                        let newDate = dayjs(date).startOf('day').add(7, 'hour')
                        onChange(newDate)
                      }}
                      className="tw-col-span-2 tw-rounded-lg"
                      sx={{
                        '& .MuiInputBase-root': {
                          backgroundColor: 'white',
                        },
                      }}
                      slotProps={{
                        textField: {
                          required: true,
                          helperText: errors.dob?.message,
                          error: !!errors.dob,
                          InputLabelProps: { required: true },
                          ref,
                          fullWidth: true,
                          inputProps: {
                            className: '!tw-py-3',
                          },
                        },
                      }}
                    />
                  </>
                )}
              />
              {/* message */}
              <Controller
                control={control}
                name="message"
                rules={{
                  required: __t('field-required'),
                  maxLength: 600,
                }}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <TextField
                      label={
                        value ? content?.lead_form?.question_field_text : null
                      }
                      placeholder={content?.lead_form?.question_field_text}
                      type="text"
                      name="message"
                      variant="outlined"
                      fullWidth
                      className="tw-col-span-2 tw-rounded-lg"
                      sx={{
                        '& .MuiInputBase-root': {
                          backgroundColor: 'white',
                        },
                      }}
                      multiline
                      rows={4}
                      inputRef={ref}
                      value={value || ''}
                      onChange={onChange}
                      error={!!errors.message}
                      helperText={errors.message?.message}
                    />
                  )
                }}
              />
            </div>
            {/* Button */}
            <PrimaryButton
              label={__t('submit')}
              addedClass="tw-w-full"
              type="submit"
              isLoading={isSubmitting}
            />
          </form>
        </div>
      </div>
    </div>
  )
}
