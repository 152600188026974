import SCP_ArabicSectionTitle from '@/components/main/arabic/ArabicSectionTitle'
import CCP_MySwiper from '@/components/main/swiper/MySwiper'
import ArabicTreatmentCard from './ArabicTreatmentCard'

export default function SCP_ArabicCenterTreatment({
  content,
  isLoading = false,
}) {
  if (!content || !content?.cards) return null

  return (
    <div className="sm:tw-py-20 tw-py-12">
      <div className="tw-container">
        {/* Title */}
        <SCP_ArabicSectionTitle
          heading={content?.heading_text}
          subHeading={content?.subheading_text}
        />
        {/* Content */}
        <div className="sm:tw-mt-16 tw-mt-12">
          <CCP_MySwiper
            breakpoints={{
              768: {
                slidesPerView: 2.4,
              },
              1080: {
                slidesPerView: 3,
              },
              1440: {
                slidesPerView: 4,
              },
            }}
            slidesPerView={1.2}
            spaceBetween={24}
            swiperClass="tw-mt-12 related-swiper"
            watchSlidesProgress={true}
            navigation
            pagination={{ clickable: true }}
            slides={(isLoading ? new Array(8).fill(0) : content?.cards).map(
              (card, i) => (
                <ArabicTreatmentCard
                  key={`clinic-card-${i}`}
                  card={card}
                  isLoading={isLoading}
                />
              )
            )}
          />
        </div>
      </div>
    </div>
  )
}
