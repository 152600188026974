import { Skeleton } from '@mui/material'
import CCP_ImageFallback from '../element/ImageFallback'

export default function ArabicTreatmentCard({ card, isLoading = false }) {
  return (
    <div
      className={`tw-w-full tw-h-full tw-flex tw-flex-col tw-group tw-shadow-main-blue tw-rounded-[20px] tw-overflow-hidden tw-duration-200 
      `}
    >
      {/* Image */}
      <div className="tw-pb-[80%] tw-w-full t-h-0 tw-relative tw-border-b">
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            className="!tw-absolute"
          />
        ) : (
          <>
            <CCP_ImageFallback
              alt={`${card?.title} Image`}
              src={card?.cover_image?.url}
              fill={true}
              className="tw-object-cover"
            />
          </>
        )}
      </div>
      {/* Details */}
      <div className="tw-p-4 tw-bg-white tw-h-full tw-flex tw-flex-col tw-justify-between">
        {isLoading ? (
          <div>
            <Skeleton
              variant="text"
              width="100%"
              height={64}
              className="!-tw-mt-4"
            />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="80%" />
          </div>
        ) : (
          <div>
            {/* Name */}
            <h6 className="tw-line-clamp-3 tw-text-2xl !tw-leading-[150%]">
              {card?.title}
            </h6>
            <p className="tw-line-clamp-2 tw-text-primary tw-my-1">
              {card?.excerpt}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
