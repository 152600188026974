import React from 'react'
import CCP_ImageFallback from '@/components/main/element/ImageFallback'
import getPostImage from '@/utils/post/getPostImage'

export default function ArabicWhyUs({ content }) {
  if (!content || !content?.cards || content?.cards?.length === 0) return null
  const bgImage = content?.background_image?.url
    ? content?.background_image?.url
    : 'https://cdn.bangkokhospital.com/2025/01/arabic-hospital.png'

  return (
    <div
      className="sm:tw-py-20 tw-py-12 tw-relative tw-bg-cover tw-bg-center"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="tw-container tw-relative tw-z-10">
        {/* Title */}
        <h3 className="animated-underline !tw-text-white max-sm:tw-w-full tw-text-center">
          {content?.heading_text || content?.service_heading}
        </h3>
        {/* Cards */}
        {content?.cards ? (
          <div className="tw-grid sm:tw-grid-cols-3 tw-gap-4 tw-pt-12">
            {content?.cards?.map((card, index) => (
              <div key={index} className="tw-p-5 tw-bg-white tw-rounded-[30px]">
                <div className="tw-relative tw-pb-fb tw-rounded-[20px] tw-overflow-hidden">
                  <CCP_ImageFallback
                    alt={`${card.title} Image`}
                    src={getPostImage(card)}
                    fill={true}
                    className="tw-object-cover"
                  />
                </div>
                <div className="tw-mb-8 tw-mt-4">
                  <h3 className="tw-text-2xl tw-font-bold tw-mt-4 tw-line-clamp-2">
                    {card?.title}
                  </h3>
                  <p className="tw-text-base tw-text-primary tw-mt-4 tw-line-clamp-4">
                    {card?.excerpt}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="bg-gradient-arabic-blue tw-opacity-80 tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0"></div>
    </div>
  )
}
