export default function SCP_ArabicSectionTitle({
  heading = '',
  subHeading = '',
}) {
  return (
    <>
      <h3 className="animated-underline max-sm:tw-w-full max-sm:tw-text-center">
        {heading}
      </h3>
      <p className="tw-text-primary tw-mt-8 max-sm:tw-w-full max-sm:tw-text-center">
        {subHeading}
      </p>
    </>
  )
}
