export default function validateEmail(email) {
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
    return false;
  }
  if (/\.(vom|xom|dom|som|fom)$/.test(email)) {
    return false;
  }
  if (/^.*@gmail\..*$/.test(email) && !email.endsWith('@gmail.com')) {
    return false;
  }
  return true;
}